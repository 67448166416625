import { Box } from '@chakra-ui/react'
import { useSpring } from 'react-spring'
import NextImage from 'next/image'
import type { ImageProps } from 'next/image'

import { DEFAULT_SPRING_CONFIG, AnimatedBox } from '@/lib/springComponent'

interface Props {
  index: number
  src: string | StaticImageData
  objectPosition?: ImageProps['objectPosition']
  position?: 'left' | 'right'
  isActive?: boolean
  alt?: string
  id?: string
  imageRefs?: React.RefObject<(HTMLDivElement | null)[]>
  circleRef?: React.RefObject<HTMLDivElement>
}

function Image({
  src,
  imageRefs,
  circleRef,
  position = 'left',
  index,
  alt = '',
  isActive = false,
  objectPosition,
}: Props): React.ReactElement {
  const { opacity, transform } = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(-50%, -50%, 0) scale(1.5)',
    },
    to: {
      transform: isActive
        ? 'translate3d(-50%, -50%, 0) scale(1)'
        : 'translate3d(-50%, -50%, 0) scale(1.5)',
      opacity: isActive ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <Box
      as="figure"
      sx={{
        position: 'relative',
        overflow: 'visible',
        w: {
          base: 'full',
          md: 'auto',
        },
      }}
    >
      <AnimatedBox
        as="figure"
        ref={(el) => {
          if (imageRefs?.current) imageRefs.current[index] = el
        }}
        sx={{
          w: {
            base: 'full',
            md: '420px',
          },
          h: { base: '410px', md: '520px' },
          position: 'relative',
          img: {
            borderRadius: '3xl',
          },
        }}
        style={{ opacity }}
      >
        <NextImage
          src={src}
          alt={alt}
          placeholder="blur"
          blurDataURL={typeof src === 'string' ? src : undefined}
          objectFit="cover"
          objectPosition={objectPosition}
          layout="fill"
          quality={90}
        />
      </AnimatedBox>
      <Box
        ref={circleRef}
        sx={{
          position: 'absolute',
          top: { base: '100%', md: '60px' },
          left: { base: '50%', md: position === 'left' ? '100%' : '0%' },
          w: { base: '86px', md: '120px' },
          h: { base: '86px', md: '120px' },
          bg: 'secondary.800',
          overflow: 'visible',
          borderRadius: 'full',
          transform: {
            base: 'translate3d(-50%, -50%, 0)',
            md: 'translate3d(-50%, 0, 0)',
          },
        }}
      >
        <AnimatedBox
          ratio={1}
          style={{ opacity, transform }}
          sx={{
            w: { base: '60px', md: '80px' },
            h: { base: '60px', md: '80px' },
            position: 'absolute',
            top: '50%',
            left: '50%',
            borderRadius: 'full',
            bg: 'primary.400',
          }}
        />
        <AnimatedBox
          style={{ opacity }}
          sx={{
            fontSize: { base: '3.75xl', md: '5xl' },
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            borderRadius: 'full',
          }}
        >
          {index + 1}
        </AnimatedBox>
      </Box>
    </Box>
  )
}

export default Image
