import { Text } from '@chakra-ui/react'
import { useTranslation, Trans } from 'next-i18next'
import { useInView } from 'react-intersection-observer'
import { useSpring } from 'react-spring'

import { DEFAULT_SPRING_CONFIG, AnimatedVStack } from '@/lib/springComponent'

function Footer(): React.ReactElement {
  const { t } = useTranslation('landing')

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const containerSpring = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: inView ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
  })

  return (
    <AnimatedVStack
      ref={ref}
      spacing={{ base: 4, md: 6 }}
      style={containerSpring}
      sx={{
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Text
        sx={{
          fontSize: {
            base: 'md',
            md: 'lg',
          },
          fontWeight: 'medium',
        }}
      >
        {t('serviceMethodology.remark')}
      </Text>
      <Text
        sx={{
          fontSize: {
            base: 'lg',
            md: '2xl',
          },
          fontWeight: 'medium',
          color: 'secondary.800',
          py: 6,
          px: 4,
          bg: 'white',
          borderRadius: '4xl',
          textAlign: 'center',
        }}
      >
        <Trans t={t} i18nKey="serviceMethodology.banner">
          พิเศษ ! ลูกค้า PDPA Implementation จะได้สิทธิใช้งาน Cookie Consent
          Management และ DSAR Management
          <Text as="span" sx={{ color: 'primary.400' }}>
            ฟรีทันที 1 ปี
          </Text>
          พร้อมนโยบายและสัญญาแบบสร้างผ่านเครื่องมืออัตโนมัติฟรี 7 รายการ
        </Trans>
      </Text>
      <Text
        sx={{
          fontSize: {
            base: 'md',
            md: 'lg',
          },
        }}
      >
        {t('serviceMethodology.terms')}
      </Text>
    </AnimatedVStack>
  )
}

export default Footer
