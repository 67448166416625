import { Text, VStack } from '@chakra-ui/react'
import { useTranslation, Trans } from 'next-i18next'

function Header(): React.ReactElement {
  const { t } = useTranslation('landing')

  return (
    <VStack
      spacing="1"
      sx={{
        alignItems: 'center',
        color: 'white',
      }}
    >
      <Text
        as="h1"
        textStyle="landingSectionSubtitle"
        sx={{
          fontWeight: 'medium',
        }}
      >
        {t('serviceMethodology.title')}
      </Text>
      <Text
        as="h1"
        textStyle="landingSectionTitle"
        sx={{
          fontSize: {
            base: '4xl',
            sm: '5xl',
          },
          fontWeight: 'bold',
        }}
      >
        <Trans t={t} i18nKey="serviceMethodology.title_en">
          <Text as="span" sx={{ color: 'primary.400' }}>
            Step of
          </Text>
          Compliance with PDPA Core
        </Trans>
      </Text>
      <Text
        as="h2"
        textStyle="landingSectionSubtitle"
        sx={{
          fontSize: {
            base: 'lg',
            sm: 'xl',
            md: '2xl',
          },
        }}
      >
        {t('serviceMethodology.subtitle')}
      </Text>
    </VStack>
  )
}

export default Header
