import { useRef, useState, useEffect } from 'react'
import { Text, Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { useInView } from 'react-intersection-observer'
import { useSpring } from 'react-spring'
import type { ImageProps } from 'next/image'

import { DEFAULT_SPRING_CONFIG, AnimatedVStack } from '@/lib/springComponent'

import Image from './Image'
import Line from './Line'
import List from './List'

type Node = React.ReactNode

export interface Props {
  index: number
  image: string | StaticImageData
  imageObjectPosition?: ImageProps['objectPosition']
  title: Node
  subtitle?: Node | Node[]
  listHeader?: string
  list?: Node[]
  imageRefs?: React.RefObject<HTMLDivElement[]>
}

function Content({
  index,
  image,
  imageObjectPosition,
  title,
  subtitle,
  listHeader,
  list,
  imageRefs,
}: Props): React.ReactElement {
  const isDesktop = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: true,
  })

  const [linePos, setLinePos] = useState<{
    active: boolean
    x: number
    y: number
    width: number
    height: number
  }>({ active: index === 0, x: 0, y: 0, width: -1, height: -1 })

  const [isImageActive, setImageActive] = useState(index === 0)

  const isInitialized = useRef(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const numberCircleRef = useRef<HTMLDivElement>(null)

  const { ref, inView, entry } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const contentSpring = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0, 16px, 0)',
    },
    to: {
      transform:
        isImageActive && inView
          ? 'translate3d(0, 0px, 0)'
          : 'translate3d(0, 16px, 0)',
      opacity: isImageActive && inView ? 1 : 0,
    },
    immediate: !isDesktop,
    config: DEFAULT_SPRING_CONFIG,
  })

  const imagePosition = index % 2 === 0 ? 'left' : 'right'
  const linePosition = index % 2 === 0 ? 'right' : 'left'

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true

      return
    }

    if (!isDesktop) {
      setImageActive(true)
    }
  }, [isDesktop])

  useEffect(() => {
    if (index === 0 || !isDesktop) return

    if (imageRefs?.current && numberCircleRef.current && containerRef.current) {
      const previouseImage = imageRefs.current[index - 1]

      const imageBoundingBox = previouseImage.getBoundingClientRect()
      const numberCircleBoundingBox =
        numberCircleRef.current.getBoundingClientRect()
      const containerBoundingBox = containerRef.current.getBoundingClientRect()

      const x =
        imageBoundingBox.x + imageBoundingBox.width / 2 - containerBoundingBox.x

      const y =
        imageBoundingBox.y + imageBoundingBox.height - containerBoundingBox.y

      const width = Math.abs(
        imageBoundingBox.x +
          imageBoundingBox.width / 2 -
          (numberCircleBoundingBox.x +
            (linePosition === 'right' ? numberCircleBoundingBox.width : 0)),
      )
      const height =
        numberCircleBoundingBox.y +
        numberCircleBoundingBox.height / 2 -
        (imageBoundingBox.y + imageBoundingBox.height)

      const newPos = {
        x,
        y,
        width,
        height,
      }

      setLinePos((currentValues) => ({
        ...currentValues,
        ...newPos,
        active: true,
      }))
    }
  }, [isDesktop, linePosition])

  return (
    <Box ref={ref}>
      <SimpleGrid
        ref={containerRef}
        sx={{
          gridTemplateColumns: {
            base: '1fr',
            md:
              imagePosition === 'left' ? 'max-content 1fr' : '1fr max-content',
          },
          gridAutoFlow: 'dense',
          gridGap: 8,
          minH: { base: 'unset', md: '800px' },
          alignItems: 'stretch',
          alignContent: 'center',
          position: 'relative',
        }}
      >
        <AnimatedVStack
          spacing="4"
          style={contentSpring}
          sx={{
            alignItems: 'flex-start',
            mt: { base: 4, md: '180px' },
            gridColumn: { base: '1', md: imagePosition === 'left' ? '2' : '1' },
            gridRow: { base: '2', md: '1' },
          }}
        >
          <Box>
            <Text
              textStyle="landingSectionTitle"
              sx={{
                fontWeight: 'medium',
                fontSize: {
                  base: '2xl',
                  md: '4xl',
                },
                textAlign: 'left',
              }}
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                textStyle="landingSectionSubtitle"
                sx={{
                  fontWeight: 'medium',
                  textAlign: 'left',
                }}
              >
                {subtitle}
              </Text>
            )}
          </Box>
          {list && <List title={listHeader} items={list} />}
        </AnimatedVStack>
        <Image
          circleRef={numberCircleRef}
          imageRefs={imageRefs}
          src={image}
          index={index}
          isActive={isImageActive && inView}
          position={imagePosition}
          objectPosition={imageObjectPosition}
        />
        {index !== 0 && linePos.active && (
          <Line
            {...linePos}
            position={linePosition}
            isActive={inView}
            isImmediate={Boolean(
              entry?.boundingClientRect.top && entry.boundingClientRect.top < 0,
            )}
            onAnimationEnd={() => setImageActive(true)}
          />
        )}
      </SimpleGrid>
    </Box>
  )
}

export default Content
