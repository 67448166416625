import { useRef } from 'react'
import { Box, useTheme, useBreakpointValue } from '@chakra-ui/react'
import { useTrail, animated } from 'react-spring'

import { DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

interface Props {
  x?: number
  y?: number
  width: number
  height: number
  position?: 'left' | 'right'
  isActive?: boolean
  isImmediate?: boolean
  onAnimationEnd?: () => void
}

function Line({
  x = 0,
  y = 0,
  width,
  height,
  position = 'left',
  isActive = false,
  isImmediate = false,
  onAnimationEnd,
}: Props): React.ReactElement {
  const isDesktop = useBreakpointValue({
    base: false,
    xs: false,
    sm: false,
    md: true,
  })
  const theme = useTheme()
  const isInitialized = useRef(false)

  const length = height + width

  const trails = useTrail(2, {
    from: {
      opacity: 0,
      strokeDashoffset: '200%',
    },
    to: {
      strokeDashoffset: isActive ? '0%' : '200%',
      opacity: isActive ? 1 : 0,
    },
    config: DEFAULT_SPRING_CONFIG,
    onRest: () => {
      if (!isInitialized.current) {
        isInitialized.current = true

        return
      }

      onAnimationEnd && onAnimationEnd()
    },
    immediate: !isDesktop || isImmediate,
  })

  return (
    <Box
      sx={{
        position: 'absolute',
        display: { base: 'none', md: 'block' },
        left: position === 'left' ? `${x}px` : `${x - width}px`,
        top: `${y}px`,
      }}
    >
      <svg
        preserveAspectRatio="none"
        width={`${width + 8}`}
        height={`${height + 8}`}
        viewBox={`-4 -4 ${width + 8} ${height + 8}`}
      >
        <animated.path
          style={{
            strokeDasharray: `${length} ${length}`,
            opacity: trails[0].opacity,
            stroke: theme.colors.secondary[950],
            strokeWidth: '8px',
            fill: 'transparent',
          }}
          d={
            position === 'left'
              ? `M0,0 0,${
                  height - 120
                } Q0,${height} 120,${height} L${width},${height}`
              : `M${width},0 ${width},${height - 120} Q${width},${height} ${
                  width - 120
                },${height} L0,${height}`
          }
        />
        <animated.path
          style={{
            strokeDashoffset: trails[1].strokeDashoffset,
            strokeDasharray: `${length} ${length}`,
            opacity: trails[1].opacity,
            stroke: theme.colors.primary[400],
            strokeWidth: '8px',
            fill: 'transparent',
          }}
          d={
            position === 'left'
              ? `M0,0 0,${
                  height - 120
                } Q0,${height} 120,${height} L${width},${height}`
              : `M${width},0 ${width},${height - 120} Q${width},${height} ${
                  width - 120
                },${height} L0,${height}`
          }
        />
      </svg>
    </Box>
  )
}

export default Line
