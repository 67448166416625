import { useRef } from 'react'
import { VStack, Box } from '@chakra-ui/react'

import {
  Header,
  Footer,
  Content,
} from '@/features/landing/components/ServiceMethodology'
import { useServiceMethodologyData } from '@/features/landing/constants/serviceMethodology'

function ServiceMethodology(): React.ReactElement {
  const SERVICE_DATA = useServiceMethodologyData()

  const imageRefs = useRef<HTMLDivElement[]>(
    new Array(SERVICE_DATA.length).fill(null),
  )

  return (
    <Box
      id="service-methodology"
      as="section"
      sx={{
        bg: 'secondary.800',
        w: 'full',
        overflow: 'hidden',
        bgImage: 'url("/images/landing/service-methodology/bg.svg")',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        bgPos: 'top',
      }}
    >
      <VStack
        spacing={{
          base: 6,
          md: 8,
        }}
        layerStyle="container"
        sx={{
          mx: 'auto',
          alignItems: 'stretch',
          color: 'white',
        }}
      >
        <Header />
        <VStack
          spacing={16}
          layerStyle="container"
          sx={{
            mx: 'auto',
            alignItems: 'stretch',
            color: 'white',
            px: 0,
          }}
        >
          {SERVICE_DATA.map((datum, index) => {
            return (
              <Content
                key={index}
                {...datum}
                index={index}
                imageRefs={imageRefs}
              />
            )
          })}
        </VStack>
        <Footer />
      </VStack>
    </Box>
  )
}

export default ServiceMethodology
