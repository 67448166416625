import { UnorderedList, HStack, Text, Stack, Box } from '@chakra-ui/react'
import { useTrail } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { AnimatedListItem, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'

interface Props {
  title?: string
  items: React.ReactNode[]
}

function List({ items, title }: Props): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const trails = useTrail(items.length, {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 16px, 0)',
    config: DEFAULT_SPRING_CONFIG,
    // immediate: true,
  })

  return (
    <Stack spacing="4" sx={{ fontSize: 'lg' }}>
      {title && (
        <Text sx={{ color: 'primary.400', fontWeight: 'medium' }}>{title}</Text>
      )}
      <Stack
        as={UnorderedList}
        sx={{ listStyleType: 'none', listStylePosition: 'inside', ml: 0 }}
        ref={ref}
      >
        {items.map((item, index) => {
          const style = trails[index]

          return (
            <AnimatedListItem key={index} style={style}>
              <HStack spacing="3" sx={{ alignItems: 'stretch' }}>
                <Box
                  sx={{
                    borderRadius: 'full',
                    bgColor: 'primary.400',
                    minW: 1,
                    maxW: 1,
                  }}
                />
                {typeof item === 'string' ? <Text>{item}</Text> : item}
              </HStack>
            </AnimatedListItem>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default List
