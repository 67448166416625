import { useMemo } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { Text } from '@chakra-ui/react'

import type { Props } from '@/features/landing/components/ServiceMethodology/Content'

import Image1 from '@/images/landing/service-methodology/image-1.jpg'
import Image2 from '@/images/landing/service-methodology/image-2.jpg'
import Image3 from '@/images/landing/service-methodology/image-3.jpg'
import Image4 from '@/images/landing/service-methodology/image-4.jpg'

export const useServiceMethodologyData = (): Omit<Props, 'index'>[] => {
  const {
    t,
    i18n: { language },
  } = useTranslation('landing')

  return useMemo(() => {
    return [
      {
        image: Image1,
        imageObjectPosition: 'top',
        title: t('serviceMethodology.contents.0.title'),
        subtitle: t('serviceMethodology.contents.0.subtitle'),
        list: [
          t('serviceMethodology.contents.0.list.0'),
          t('serviceMethodology.contents.0.list.1'),
          t('serviceMethodology.contents.0.list.2'),
        ],
      },
      {
        image: Image2,
        title: t('serviceMethodology.contents.1.title'),
        subtitle: t('serviceMethodology.contents.1.subtitle'),
        listHeader: t('serviceMethodology.contents.1.listHeader'),
        list: [
          t('serviceMethodology.contents.1.list.0'),
          t('serviceMethodology.contents.1.list.1'),
          t('serviceMethodology.contents.1.list.2'),
          <Text key="4">
            <Trans t={t} i18nKey="serviceMethodology.contents.1.list.3">
              เอกสารอื่นๆ มากกว่า
              <Text
                as="strong"
                sx={{ color: 'primary.400', fontWeight: 'medium' }}
              >
                10+
              </Text>
              รายการ
            </Trans>
          </Text>,
          t('serviceMethodology.contents.1.list.4'),
        ],
      },
      {
        image: Image3,
        imageObjectPosition: 'top',
        title: t('serviceMethodology.contents.2.title'),
        subtitle: t('serviceMethodology.contents.2.subtitle'),
      },
      {
        image: Image4,
        imageObjectPosition: 'top',
        title: t('serviceMethodology.contents.3.title'),
        list: [
          t('serviceMethodology.contents.3.list.0'),
          t('serviceMethodology.contents.3.list.1'),
        ],
      },
    ]
  }, [language])
}
